@keyframes cssload-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes cssload-no-visible {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.animation {
  animation: cssload-animation 0.5s linear;
  -o-animation: cssload-animation 0.5s linear;
  -ms-animation: cssload-animation 0.5s linear;
  -webkit-animation: cssload-animation 0.5s linear;
  -moz-animation: cssload-animation 0.5s linear;
}

.no-visible {
  animation: cssload-no-visible 5s linear;
  -o-animation: cssload-no-visible 5s linear;
  -ms-animation: cssload-no-visible 5s linear;
  -webkit-animation: cssload-no-visible 5s linear;
  -moz-animation: cssload-no-visible 5s linear;
}
