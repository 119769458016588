*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: 'Roboto', sans-serif;
}

a {
  display: inline-block;
  text-decoration: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: -0 16px;
  min-width: 1024px;
}

.slide-enter {
  left: -35vw;
}
.slide-enter-active {
  left: 0;
  transition: left 200ms;
}
.slide-exit {
  left: 0;
}
.slide-exit-active {
  left: -35vw;
  transition: left 200ms;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #dcdcdc;
}

::-webkit-scrollbar-thumb {
  background-color: #c5d7e0;
  border-radius: 2px;
}
